/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import styled from 'styled-components'

// Elements
import Image from 'components/shared/Image2'
import ParseContent from 'components/shared/ParseContent'

const StyledCustomTextTextWithIcon = styled.section``

const CustomTextTextWithIconIcon = styled(Image)`
  position: absolute;

  @media (min-width: 992px) {
    left: -175px;
    top: 25px;
    width: 250px;
    height: 250px;
  }

  @media (max-width: 991px) {
    left: -125px;
    top: 50px;
    width: 150px;
    height: 150px;
  }

  @media (max-width: 767px) {
    left: calc(100% - 125px);
    top: 0;
    width: 100px;
    height: 100px;
  }
`

interface CustomTextTextWithIconProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_CustomText
}

const CustomTextTextWithIcon: React.FC<CustomTextTextWithIconProps> = ({
  fields,
}) => (
  <StyledCustomTextTextWithIcon className="my-1 my-lg-5">
    <div className="container py-5 position-relative">
      <CustomTextTextWithIconIcon image={fields.icon} />
      <div className="row justify-content-center">
        <div className="col-sm-10 col-lg-9">
          <ParseContent content={fields.description} />
        </div>
      </div>
    </div>
  </StyledCustomTextTextWithIcon>
)

export default CustomTextTextWithIcon
