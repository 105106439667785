// eslint-disable-next-line import/no-extraneous-dependencies
import React from 'react'

// Components
import CustomTextQuote from 'components/flex/CustomText/CustomTextQuote'
import CustomTextTextWithIcon from 'components/flex/CustomText/CustomTextTextWithIcon'
import CustomTextRoundedImage from 'components/flex/CustomText/CustomTextRoundedImage'
import CustomTextImageText from 'components/flex/CustomText/CustomTextImageText'
import CustomTextCTA from 'components/flex/CustomText/CustomTextCTA'

interface CustomTextProps {
  fields: any
  location?: any
}

interface CustomTextsProps {
  [key: string]: any
}

const CustomText: React.FC<CustomTextProps> = ({ fields, location = {} }) => {
  const customTexts: CustomTextsProps = {
    quote: CustomTextQuote,
    textWithIcon: CustomTextTextWithIcon,
    roundedImage: CustomTextRoundedImage,
    imageText: CustomTextImageText,
    cta: CustomTextCTA,
  }

  if (!fields.view || !customTexts[fields.view]) {
    return null
  }

  const Component = customTexts[fields.view]

  return (
    <Component
      key={`${location ? location.pathname : ''}`}
      fields={fields}
      location={location}
    />
  )
}

export default CustomText
