/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import styled, { css } from 'styled-components'

// Elements
import Image from 'components/shared/Image2'
import ParseContent from 'components/shared/ParseContent'

const StyledCustomTextCTA = styled.section`
  position: relative;
`

const CustomTextCTABackgroundLeft = styled.div`
  background-color: ${(props) => props.theme.color.face.background};
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 1;

  @media (min-width: 768px) {
    width: 50%;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`

const CustomTextCTABackground = styled.div`
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
`

const CustomTextCTABackgroundRight = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.color.face.background};
  height: 100%;
  width: 100%;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
`

const CustomTextCTAContainer = styled.div`
  position: relative;
  z-index: 2;
`

const CustomTextCTAIcon = styled(Image)`
  margin: 0 auto;
  display: block;

  & img {
    object-fit: contain !important;
  }

  @media (min-width: 992px) {
    height: 350px;
    width: 250px;
  }

  @media (max-width: 991px) {
    height: 250px;
    width: 200px;
  }
`

const CustomTextCTAIconContent = styled(ParseContent)`
  position: relative;
  z-index: 2;
`

const CustomTextCTACircle = css`
  background-color: ${(props) => props.theme.color.face.border};
  position: absolute;
  border-radius: 50%;
`

const CustomTextCTACircle1 = styled.div`
  ${CustomTextCTACircle};
  height: 45px;
  width: 45px;
  left: 15%;
  top: 15%;
`

const CustomTextCTACircle2 = styled.div`
  ${CustomTextCTACircle};
  height: 110px;
  width: 110px;
  left: 10%;
  bottom: 15%;
`

const CustomTextCTADescription = styled(ParseContent)`
  & a {
    text-decoration: underline;
  }

  & .button a {
    text-decoration: none;
  }
`

interface CustomTextCTAProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_CustomText
}

const CustomTextCTA: React.FC<CustomTextCTAProps> = ({ fields }) => (
  <StyledCustomTextCTA className="my-1 my-lg-5">
    <CustomTextCTABackgroundLeft />
    <CustomTextCTABackground>
      <div className="container h-100">
        <div className="row h-100 d-md-flex d-none">
          <div className="col-12 h-100">
            <CustomTextCTABackgroundRight />
          </div>
        </div>
      </div>
    </CustomTextCTABackground>
    <CustomTextCTAContainer className="container py-5 position-relative">
      <div className="row">
        <div className="col-sm-5 col-lg-6 mt-5 mt-lg-0 position-relative d-flex align-items-center justify-content-center order-2 order-md-1">
          <CustomTextCTACircle1 />
          <CustomTextCTACircle2 />
          <div>
            <CustomTextCTAIcon image={fields.image} />
            <CustomTextCTAIconContent
              className="mt-4"
              content={fields.imageDescription}
            />
          </div>
        </div>
        <div className="col-sm-7 col-lg-6 px-lg-5 mt-lg-5 mt-sm-0 order-1 order-md-2">
          <CustomTextCTADescription content={fields.description} />
        </div>
      </div>
    </CustomTextCTAContainer>
  </StyledCustomTextCTA>
)

export default CustomTextCTA
