/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import styled from 'styled-components'

// Elements
import Image from 'components/shared/Image2'
import ParseContent from 'components/shared/ParseContent'

const StyledCustomTextImageText = styled.section`
  position: relative;
`

const CustomTextImageTextBackgroundLeft = styled.div`
  background-color: ${(props) => props.theme.color.face.background};
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  z-index: 1;

  @media (min-width: 768px) {
    width: 50%;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`

const CustomTextImageTextBackground = styled.div`
  position: absolute;
  z-index: 1;
  height: 100%;
  width: 100%;
`

const CustomTextImageTextBackgroundRight = styled.div`
  position: absolute;
  background-color: ${(props) => props.theme.color.face.background};
  height: 100%;
  width: 100%;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
`

const CustomTextImageTextContainer = styled.div`
  position: relative;
  z-index: 2;
`

const CustomTextImageTextIcon = styled(Image)`
  & img {
    object-fit: contain !important;
  }

  @media (min-width: 992px) {
    width: 500px;
    height: 500px;
  }

  @media (max-width: 991px) {
    width: 350px;
    height: 350px;
  }

  @media (max-width: 767px) {
    width: 250px;
    height: 250px;
  }
`

interface CustomTextImageTextProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_CustomText
}

const CustomTextImageText: React.FC<CustomTextImageTextProps> = ({
  fields,
}) => (
  <StyledCustomTextImageText className="my-1 my-lg-5">
    <CustomTextImageTextBackgroundLeft />
    <CustomTextImageTextBackground>
      <div className="container h-100">
        <div className="row h-100 d-md-flex d-none">
          <div className="col-12 h-100">
            <CustomTextImageTextBackgroundRight />
          </div>
        </div>
      </div>
    </CustomTextImageTextBackground>
    <CustomTextImageTextContainer className="container py-5 position-relative">
      <div className="row">
        <div className="col-sm-6 d-flex align-items-center mt-5 mt-lg-0 justify-content-center order-2 order-md-1">
          <div>
            <CustomTextImageTextIcon image={fields.image} />
            <ParseContent content={fields.imageDescription} />
          </div>
        </div>
        <div className="col-sm-6 px-lg-5 mt-lg-5 mt-sm-0 order-1 order-md-2">
          <ParseContent content={fields.description} />
        </div>
      </div>
    </CustomTextImageTextContainer>
  </StyledCustomTextImageText>
)

export default CustomTextImageText
