/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import styled from 'styled-components'

// Elements
import Image from 'components/shared/Image2'
import ParseContent from 'components/shared/ParseContent'
import { ClientCaseBlockQuote } from 'components/flex/ClientCase'

const StyledCustomTextQuote = styled.section``

const CustomTextQuoteIcon = styled(Image)`
  position: absolute;

  @media (min-width: 992px) {
    left: -250px;
    top: -75px;
    width: 250px;
    height: 250px;
  }

  @media (max-width: 991px) {
    left: calc(100% - 100px);
    top: -25px;
    width: 125px;
    height: 125px;
  }
`

const CustomTextQuoteDescription = styled(ParseContent)`
  & a {
    text-decoration: underline;
  }

  & .button a {
    text-decoration: none;
  }
`

const CustomTextQuoteClientCaseBlockQuote = styled(ClientCaseBlockQuote)`
  top: -50px;

  & path {
    fill: ${(props) => props.theme.color.text.grey};
  }
`

interface CustomTextQuoteProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_CustomText
}

const CustomTextQuote: React.FC<CustomTextQuoteProps> = ({ fields }) => (
  <StyledCustomTextQuote className="my-1 my-lg-5">
    <div className="container py-5 position-relative">
      <CustomTextQuoteIcon image={fields.icon} />
      <div className="row">
        <div className="col-md-6">
          <CustomTextQuoteDescription content={fields.description} />
        </div>
        <div className="col-md-6 d-flex align-items-center pt-5 mt-5">
          <div className="p-lg-3 p-xl-4 position-relative">
            <CustomTextQuoteClientCaseBlockQuote />
            <ParseContent content={fields.quote} />
          </div>
        </div>
      </div>
    </div>
  </StyledCustomTextQuote>
)

export default CustomTextQuote
