/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import styled from 'styled-components'

// Elements
import Image from 'components/shared/Image2'
import ParseContent from 'components/shared/ParseContent'

const StyledCustomTextRoundedImage = styled.section`
  position: relative;
`

const CustomTextRoundedImageBackgroundRight = styled.div`
  background-color: ${(props) => props.theme.color.face.background};
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  z-index: 1;

  @media (min-width: 768px) {
    width: 50%;
  }

  @media (max-width: 767px) {
    width: 100%;
  }
`

const CustomTextRoundedImageBackground = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  z-index: 1;
`

const CustomTextRoundedImageBackgroundLeft = styled.div`
  background-color: ${(props) => props.theme.color.face.background};
  height: 100%;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
`

const CustomTextRoundedImageContainer = styled.div`
  position: relative;
  z-index: 2;

  @media (min-width: 991px) {
    & h2 {
      & strong {
        font-size: 43px;
      }
    }
  }
`

const CustomTextRoundedImageIcon = styled(Image)`
  overflow: hidden;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  top: -75px;

  @media (min-width: 1200px) {
    min-width: 525px;
    min-height: 525px;
    max-width: 525px;
    max-height: 525px;
  }

  @media (max-width: 1199px) {
    min-width: 400px;
    min-height: 400px;
    max-width: 400px;
    max-height: 400px;
  }

  @media (max-width: 991px) {
    min-width: 250px;
    min-height: 250px;
    max-width: 250px;
    max-height: 250px;
  }

  @media (max-width: 767px) {
    min-width: 225px;
    min-height: 225px;
    max-width: 225px;
    max-height: 225px;
    left: -25px;
  }

  @media (max-width: 575px) {
    min-width: 200px;
    min-height: 200px;
    max-width: 200px;
    max-height: 200px;
    left: 0;
    top: 0;
  }
`

interface CustomTextRoundedImageProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_CustomText
}

const CustomTextRoundedImage: React.FC<CustomTextRoundedImageProps> = ({
  fields,
}) => (
  <StyledCustomTextRoundedImage className="my-1 my-lg-5">
    <CustomTextRoundedImageContainer className="container py-5 position-relative">
      <div className="row justify-content-end">
        <div className="col-sm-5 col-lg-6 d-flex justify-content-center">
          <CustomTextRoundedImageIcon image={fields.image} />
        </div>
        <div className="col-sm-7 col-lg-6 mt-5 mt-sm-0">
          <ParseContent content={fields.description} />
        </div>
      </div>
    </CustomTextRoundedImageContainer>
    <CustomTextRoundedImageBackgroundRight />
    <CustomTextRoundedImageBackground>
      <div className="row h-100 d-md-flex d-none justify-content-end">
        <div className="col-12 col-md-9 h-100">
          <CustomTextRoundedImageBackgroundLeft />
        </div>
      </div>
    </CustomTextRoundedImageBackground>
  </StyledCustomTextRoundedImage>
)

export default CustomTextRoundedImage
